import React, { useEffect, useState } from 'react'
import { Filter, Search, Upload, Trash2, X, Sliders, ChevronLeft } from 'react-feather';
import DataTable from 'react-data-table-component';
import draftsYellow from '../../images/Techbzar/drafts-yellow.png';
import draftsGrey from '../../images/Techbzar/drafts-grey.png';
import axios from 'axios';
import { HOST2 } from '../../Api/api';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parse } from 'date-fns'
import Temp from './Temp';
import { useNavigate } from 'react-router-dom';

function Table() {
  const [showParameter, setShowparameter] = useState(false);//to show and hide parametr
  const [productName, setProductname] = useState("")//to show in the modal header
  const [subCategory, setSubCategory] = useState("")//to handle parameter in the modal
  const [selectedBrand, setSelectedBrand] = useState([]);//to select multiple brand
  const [selectedSubcategory, setSelectedsubcategory] = useState([]);//to selectmultiple subcaetgory
  const [selectedStatus, setSelectedStatus] = useState({});//to select status
  const [appliedFilter, setAppliedFilter] = useState(false);//to handle filter
  const [data, setData] = useState([]); // Data state
  const [filteredData, setFilteredData] = useState([]);//
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const [selectedRows, setSelectedRows] = useState([]); // Selected rows
  const [loading, setLoading] = useState(false);//to start loader
  const [showModal, setShowModal] = useState(false); // to show modal
  const [type, setType] = useState("");//selecting the type of delete
  const [deleteone, setDeleteone] = useState([]);//payload data to delete single record
  const [uploadloading, setUploadloading] = useState(false);//Manage loader during Uploading
  const [uploadtype, setUploadtype] = useState("");//Manage loader according to the type of upload
  const [uploadProduct, setUploadproduct] = useState("")//to manage loader according to product name
  const [show, setShow] = useState(false);//to handle filter
  const [startDate, setStartDate] = useState("");//to select start date
  const [endDate, setEndDate] = useState("");//to select end date
  const [options, setOptions] = useState([])//to show brands in dropdown
  const [SubCategory, setSubcategory] = useState([])//to show subcategoryin the dropdown

  const navigate = useNavigate();

  const optionsStatus = [
    { value: 'Published', label: 'Published' },
    { value: 'Unpublished', label: 'Unpublished' },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  //Function to apply filter
  const filterByDateRange = () => {
    let filtered = [...data];
    if (startDate && endDate) {
      const normalizeDate = (date) => {
        // Normalize date by setting time to 00:00:00
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      };
      const normalizedStartDate = normalizeDate(startDate);
      const normalizedEndDate = normalizeDate(endDate);
      filtered = data.filter((row) => {
        const rowDate = parse(row.Time, "dd-MM-yyyy hh:mm a", new Date()); // Parse Time field
        const normalizedRowDate = normalizeDate(rowDate); // Normalize rowDate
        return (
          normalizedRowDate >= normalizedStartDate &&
          normalizedRowDate <= normalizedEndDate
        );
      });
    }
    // Filter by brand
    if (selectedBrand.length > 0) {
      const selectedBrandValues = selectedBrand.map((brand) => brand.value);
      filtered = filtered.filter((item) => selectedBrandValues.includes(item.Brand));
    }
    //Filter by subcategory
    if (selectedSubcategory.length > 0) {
      const selectedSubcategoryValues = selectedSubcategory.map((subcategory) => subcategory.value);
      filtered = filtered.filter((item) => selectedSubcategoryValues.includes(item.Sub_Category))
    }
    //Filter by status
    if (Object.keys(selectedStatus).length > 0) {
      const selectedStatusValue = selectedStatus.value;
      filtered = filtered.filter((item) => item.Status === selectedStatusValue);

    }
    setFilteredData(filtered);
    setAppliedFilter(true);
    setShow(false);
  };

  //to handle search
  let baseData = appliedFilter ? filteredData : data;
  const searchedData = baseData.filter((item) => item.Product_Name.toLowerCase().startsWith(searchTerm.toLowerCase()));

  //function to resset filter
  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSelectedBrand([]);
    setSelectedsubcategory([]);
    setSelectedStatus({});
    setAppliedFilter(false);
  }

  // Handle Row Selection
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  //fetching data
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(HOST2 + "/list_product_data")
      if (response.status === 200) {
        setData(response.data.data);
        const uniqueOptions = response.data.Brands.map((brand) => ({
          value: brand,
          label: brand
        }));
        const uniqueSubcategory = response.data.Sub_Category.map((subcategory) => ({
          value: subcategory,
          label: subcategory
        }));
        setSubcategory(uniqueSubcategory);
        setOptions(uniqueOptions);
      }
    }
    catch (error) {
      toast.error("Failed to fetch data" || error.response?.data?.message);
    }
    finally {
      setLoading(false);
    }
  }

  //To Delete Data
  const handle_Delete = async (type) => {
    try {
      let payload;
      if (type === "bulk") {
        payload = selectedRows.map((item) => ({
          "Brand": item.Brand,
          "Product_Name": item.Product_Name
        }));
      }
      else if (type === "single") {
        payload = deleteone
      }
      const response = await axios.put(HOST2 + "/delete_product_data", payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      });
      if (response.status === 200) {
        fetchData();
      }
    }
    catch (error) {
      toast.error("something went wrong" || error.response?.data?.message);
    }
    finally {
      setShowModal(false);
    }
  }

  //Function to upload data
  const handle_upload = async (upload_type, row) => {
    if (upload_type === "bulk") {
      const publishedItem = selectedRows.find((item) => item.Status === "Published");
      if (publishedItem) {
        toast.warn("Only unpublished records can be published.");
        return;
      }
      const firstSubCategory = selectedRows[0].Sub_Category;//finding first record subcategory
      const isSameSubCategory = selectedRows.every(
        (item) => item.Sub_Category === firstSubCategory
      );
      if (!isSameSubCategory) {
        toast.error("Only records with the same sub-category can be published.");
        return;
      }
    }
    setUploadloading(true);
    try {
      let upload_payload;
      if (upload_type === "single" && row) {
        upload_payload = [
          {
            "Brand": row.Brand,
            "Product_Name": row.Product_Name
          }
        ];
      }
      if (upload_type === "bulk") {
        upload_payload = selectedRows.map((item) => ({
          "Brand": item.Brand,
          "Product_Name": item.Product_Name
        }));
      }
      const response = await axios.post(HOST2 + "/update_status", upload_payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      });
      if (response.status === 200) {
        toast.success("Sucessfully Published")
        fetchData();
      }
      console.log(upload_payload);
      console.log(upload_type);
    }
    catch (error) {
      toast.error("Something went wrong" || error.response?.data?.message)
    }
    finally {
      setUploadloading(false);
      setUploadtype("");
      setUploadproduct("");
    }
  }

  //Function to handle parameter
  const handleParemeter = (row) => {
    setShowparameter(true);
    setProductname(row.Product_Name);
    setSubCategory(row.Sub_Category);
  }

  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer', // Set cursor to pointer on hover
      },
    },
  };
  const columns = [
    {
      name: "Last Activity",
      selector: (row) => row.Time,
      sortable: true,
      maxWidth: '180px',
    },
    {
      name: "Brand",
      selector: (row) => row.Brand,
      sortable: true,
      maxWidth: '140px',  // Adjust as needed
    },
    {
      name: "Product Name",
      selector: (row) => row.Product_Name,
      sortable: true,
      maxWidth: '600px',
    },
    {
      name: "Sub-Category",
      selector: (row) => row.Sub_Category,
      sortable: true,
      maxWidth: '180px',
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      cell: (row) => (
        <span style={{ color: row.Status === "Published" ? "grey" : "#007CC2" }}>
          {row.Status}
        </span>
      ),
      sortable: true,
      maxWidth: '160px',
    },
    {
      name: "Action",
      maxWidth: '200px',
      cell: (row) => (
        <div>
          <button className='border-0 bg-transparent' onClick={() => {
            setType("single");
            setDeleteone([{ "Brand": row.Brand, "Product_Name": row.Product_Name }]);
            setShowModal(true)
          }}>
            <Trash2 color='#E64C4C' size={20} />
          </button>
          <button className='border-0 bg-transparent ms-4' onClick={() => alert(`Editing ${row.Product_Name}`)} disabled>
            <img className='drafts-icon' src={draftsGrey} alt="" />
          </button>
          <button className='border-0 bg-transparent ms-4' disabled={row.Status === "Published"} onClick={() => {
            setUploadtype("single");
            setUploadproduct(row.Product_Name);
            handle_upload("single", row);
          }
          }>
            {uploadloading && uploadtype === "single" && uploadProduct === row.Product_Name ? (
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
                style={{ width: '1.5rem', height: '1.5rem' }}
              ></div>
            ) : (
              <Upload style={{ color: row.Status === "Published" ? "grey" : "#007CC2" }} size={20} />
            )}
          </button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className='pt-3'>
        <div className='d-flex flex-column align-items-center justify-content-center text-center'>
          <div className='row px-5 w-100'>
            <div className="col-lg-1 p-0">
              <button className='border-leads-blue bg-white rounded-pill p-2 d-flex justify-content-center align-items-center' onClick={()=>{navigate("/fileupload")}}>
                <ChevronLeft size={24} color='#007CC2' />
                <span className='mx-2 text-leads-blue fw-semibold'>Back</span>
              </button>
            </div>
            <div className="col-lg-11 p-0">
              <h2 className=''>CM AI SEO Content Generator</h2>
              <p className='text-secondary'>Automate Product Content Creation and Listing for E-commerce Websites with Generative AI Algorithms.</p>
            </div>
          </div>
        </div>
        <div className='mt-3 mx-5 mx-md-2 mx-lg-5 d-flex flex-column flex-lg-row flex-md-row justify-content-between'>
          <div>
            <button className='bg-leads-blue text-white border-0 rounded px-5 py-2'
              onClick={() => {
                if (selectedRows.length <= 0) {
                  toast.warning("Please select atleast one record");
                  return;
                }
                setUploadtype("bulk");
                handle_upload("bulk");
              }}>
              {uploadloading && uploadtype === "bulk" ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                  style={{ width: '1.5rem', height: '1.5rem' }}
                ></div>
              ) : (
                selectedRows.length > 0 ? `Upload (${selectedRows.length})` : "Upload"
              )}
            </button>
            <button className='bg-leads-red text-white border-0 rounded px-5 py-2 ms-2'
              onClick={() => {
                if (selectedRows.length <= 0) {
                  toast.warning("Please select atleast one record");
                  return;
                }
                setType("bulk");
                setShowModal(true);
              }}>
              {selectedRows.length > 0 ? `Delete (${selectedRows.length})` : "Delete"}
            </button>
          </div>
          <div className='d-flex align-items-center mt-3 mt-md-0 mt-lg-0'>
            <div className="d-flex">
              <div className="bg-white rounded border border-light-subtle d-flex align-items-center">
                <Search className='ms-3' size={24} color='#777' />
                <input
                  type="text"
                  className="form-control me-2 border-0"
                  placeholder="Search..."
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div class="dropdown ms-2">
              <button class="btn dropdown-toggle text-black border border-light-subtle py-2 bg-white" onClick={() => { setShow((prevState) => !prevState); }}>
                <Sliders size={20} color='#777' />
                <span className='ms-2 text-secondary'>Filter</span>
              </button>
              {show && (
                <div className='position-absolute shadow rounded d-flex flex-column p-3 bg-white filterdivalign'>
                  <div className='d-flex flex-row justify-content-between'>
                    <p className='m-0'>Filter</p>
                    <X
                      color='#777'
                      size={20}
                      onClick={() => setShow(false)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <hr className='mt-2 my-0' />
                  <div className='justify-content-between mt-3'>
                    <div className='d-flex flex-row justify-content-between'>
                      <p className='m-0 text-color-a font-14'>Select Date</p>
                      <button className='m-0 text-leads-blue font-14 bg-transparent border-0 ' onClick={() => { setStartDate(""); setEndDate(""); }}>Clear</button>
                    </div>
                    <div>
                      <div className='d-flex flex-row justify-content-between'>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          placeholderText="Start Date"
                          dateFormat="dd/MM/yyyy"
                          startDate={startDate}
                          endDate={endDate}
                          className='date-picker text-color-7 font-14 border border-secondary-subtle py-1 px-2 rounded rounded-1'
                        />
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          placeholderText="End Date"
                          dateFormat="dd/MM/yyyy"
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          className='date-picker text-color-7 font-14 border border-secondary-subtle py-1 px-2 rounded rounded-1'
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex flex-row justify-content-between mt-3'>
                      <p className='m-0 text-color-a font-14'>Brands</p>
                      <button className='m-0 text-leads-blue font-14 bg-transparent border-0' onClick={() => setSelectedBrand([])}>Clear</button>
                    </div>
                    <div>
                      <Select
                        className='font-14'
                        isMulti
                        options={options}
                        value={selectedBrand} //Controlled value
                        onChange={(selected) => { setSelectedBrand(selected); }}
                        placeholder="Select Brand"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className='d-flex flex-row justify-content-between mt-3'>
                      <p className='m-0 text-color-a font-14' >Sub-Category</p>
                      <button className='m-0 text-leads-blue font-14 bg-transparent border-0' onClick={() => setSelectedsubcategory([])}>Clear</button>
                    </div>
                    <div>
                      <Select
                        className='font-14'
                        isMulti
                        options={SubCategory}
                        value={selectedSubcategory}//controlled value
                        onChange={(selected) => { setSelectedsubcategory(selected); }}
                        placeholder="Select Sub-Category"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className='d-flex flex-row justify-content-between mt-3'>
                      <p className='m-0 text-color-a font-14' >Status</p>
                      <button className='m-0 text-leads-blue font-14 bg-transparent border-0' onClick={() => setSelectedStatus({})}>Clear</button>
                    </div>
                    <div>
                      <Select
                        className='font-14'
                        options={optionsStatus}
                        value={selectedStatus}
                        onChange={(selected) => { setSelectedStatus(selected); }}
                        placeholder="Select Status"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <button className='py-1 rounded col-3 font-14 border-secondary-subtle border bg-transparent' onClick={handleReset}>
                      Reset
                    </button>
                    <button className='text-white bg-leads-blue font-14 border-0 py-1 rounded col-6' onClick={filterByDateRange}>
                      Apply Filter
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        loading ? (
          <div className='d-flex justify-content-center align-items-center'>Loading...</div>
        ) :
          (
            <div className='Table px-5 py-2'>
              <DataTable
                fixedHeader
                columns={columns}
                data={searchedData}
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                highlightOnHover
                defaultSortField="Time"
                onRowClicked={(row) => handleParemeter(row)}
                customStyles={customStyles}
              />
            </div>
          )
      }
      {showModal && (
        <div className="modal show" style={{ display: "block" }} role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fs-5">Delete Record?</h4>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p className='text-color-a font-14 mb-0'>Are you sure you want to delete</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={() => handle_Delete(type)}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showParameter && (
        <div>
          <Temp
            setShowparameter={setShowparameter}
            Product_Name={productName}
            Sub_Category={subCategory}
          />
        </div>
      )}

    </div>
  )
}

export default Table;